.sdc-meta-footer {
  &.sdc-meta-footer--container {
    max-width: $site-max-width;
    margin: auto;
    padding-left:24px;
    padding-right:24px;
  }
  p {
    margin-bottom:1.5em
  }
}