.sdc-article-header__wrap[class] {
  max-width: var(--site-width-inner);
}

.sdc-article-header__title[class],
.sdc-article-header__sub-title[class] {
  max-width: 800px;
}

.sdc-article-image__caption-text[class][class] {
  max-width: 100%!important;
}

.ui-advert[data-type="ghost"] + .sdc-article-header--feature {
  margin-top: calc(var(--component-margin) * -1);
}

.sdc-article-header__wrap[class] {
  max-width: 976px !important;
}

@media all and (min-width: 940px){
  .sdc-site-layout,
  .sdc-article-quote--with-image[class] {
    margin: 0 var(--space-m);
  }
}