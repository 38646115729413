.ui-advert {
  --label-text: "Anzeige" !important;
}

.ui-advert[data-ad-format=leaderboard]:before {
  padding: 0 0 6px !important;
}

.sdc-advert {
  &.sdc-advert--top-container {
    display: flex;
    justify-content: center;
    margin-top: var(--component-margin);
  }

  &.sdc-advert--top-spaced {
    padding-top: $component-vertical-margin-s;
  }

  .sdc-advert__top-1 {
    width: 100%;
    max-width: 90%;

    @media(min-width:1024px){
      max-width: $site-max-width;
    }
  }

  .sdc-advert__side {
    flex: 1;
    display: flex;

    @media all and (max-width: calc(#{$site-max-width} + (300px * 2))) {
      display: none;
    }

    &.sdc-advert__side--top-2 {
      justify-content: flex-end;
    }

    &.sdc-advert__side--top-3 {
      justify-content: flex-start;
    }
  }

  .sdc-advert__no-height {
    position: absolute;
  }

  &.sdc-advert--article-sponsor-container {
    position: relative;
    max-width: 976px;
    margin-left: auto;
    margin-right: auto;
  }

  .sdc-advert__article-sponsor {
    position: absolute;
    bottom: 2.5em;
    width: 100%;
    direction: rtl;
    margin-left: auto;
    margin-right: auto;

    @media #{$bp-m} {
      padding-left: $gutter-m;
      padding-right: $gutter-m
    }

    @media #{$bp-s} {
      padding-left: $gutter-s;
      padding-right: $gutter-s;
    }

    @media #{$bp-xs} {
      padding-left: $gutter-xs;
      padding-right: $gutter-xs;
      bottom: 1.5em;
    }
  }
}

// Advert-related overrides for F1 profile pages.
@media all and (min-width: calc(#{$site-max-width} + 601px)) {
  .sdc-advert ~ [data-clip-width] {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--site-width-inner);
  }
}

@media all and (max-width: 939px) {
  .sdc-site-layout__col2 + .hide-mpu--mobile {
    display: none;
  }
}

.sdc-site-tiles ~ .site-component-vertical-margin-l .ui-advert[data-ad-format="leaderboard"],
.sdc-site-au--full-bleed:has(~section .sdc-site-carousel__wrap) .ui-advert[data-ad-format="leaderboard"],
.sdc-site-au--full-bleed .ui-advert-slot[data-ad-format="leaderboard"] {
  background-color: unset;
}

// Make sure ad ghost has height
.sdc-site-layout-sticky-region + .ui-advert[data-type="ghost"] {
  min-height: calc(90px + 1em + 1.4vmin);
}

@media (max-width: 739px) {
  .sdc-site-layout-sticky-region + .ui-advert[data-type="ghost"] {
    min-height: calc(50px + 1em + 1.4vmin);
  }
}

@media (min-width: 740px) {
  .ui-advert.sdc-site-au__mpu--watch[data-ad-format="mpu-1"] {
    display: none;
  }
}
