.sdc-site-fixres__additional-items {
  text-align: center;
  padding-top: 1.5em;
}

.sdc-site-fixres__additional-link {
  display: inline-block;
  font-size: 18px;
}

// Overrides incorrect setting in used version of ui-project-base
.sdc-site-localnav {
  z-index: 9999;
  &.sdc-site-localnav--filter {
    z-index: 999;
  }
}

.sdc-site-footer {
  z-index: 999!important;
}
// Adds styles for rainbow header
.sdc-site-header--rainbow {
  padding-bottom: 5px;
}

.sdc-site-header--rainbow:after {
  content: "";
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, #d3222a 0%, #d3222a 14.2857%, #ed7f04 14.2857%, #ed7f04 28.5714%, #fcbf02 28.5714%, #fcbf02 42.8571%, #75b72d 42.8571%, #75b72d 57.1428%, #4dbbec 57.1428%, #4dbbec 71.4286%, #88417e 71.4286%, #88417e 85.7142%, #df1354 85.7142%, #df1354 100%);
  background-size: 50%;
}
