.section-wrap__header-date-time .sdc-article-date__wrap,
.sdc-article-date__wrap {
  display: flex;
}

.section-wrap__header-details,
.section-wrap__header-date-time .sdc-article-date__icon,
.sdc-article-date__wrap .sdc-article-date__icon,
.sdc-article-date__wrap {
  color: #707070;
  fill: #707070;
}

.section-wrap__header-details {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--site-width);
  padding-left: var(--site-gutter);
  padding-right: var(--site-gutter);
}

.section-wrap__header-date-time .sdc-article-date__wrap {
  margin-bottom: 20px;
}

.sdc-article-date__wrap {
  align-items: center;
}