/* For identifying which tests a localnav should be covered by. 
   Uncomment these to apply a colour to any unknown localnavs causing a test fail so you can identify which testId they need
   DO NOT DELETE!

.sdc-site-localnav:nth-child(9) { border: 5px solid red !important; } /* test-id='sitewide-localnav--match-pages'
.sdc-site-localnav--filter { border: 5px solid hotpink !important; } /* test-id='sitewide-localnav--general-subnav'
.sdc-site-localnav:nth-child(3){ border: 5px solid green !important; } /* test-id='sitewide-localnav--sitewide-header-subnav'
main  h1 .sdc-site-localnav__header-title,
main .sdc-site-localnav__item[data-role='nav-item'],
main .sdc-site-localnav__item--more[aria-hidden*='false'] { border: 5px solid blue !important; } /* test-id='sitewide-localnav--tables-pages-subnav'
*/

// refactor and delete before launch

// variables

// overrides z-index on match header pages, so secondary nav sits below video on DE when video is floating via HolaSpark
.sdc-site-localnav ~ .sdc-site-localnav {
  z-index: 9985 !important;
}

// Overrides the value 99999 to allow sticky leaderboard priority
.sdc-site-match-header__body .sdc-site-match-header__content {
  z-index: 9985;
}

// overrides yellow background on breaking tiles, as not yet approved by DE stakeholders
.sdc-site-tile--breaking {
  background-color: #fff !important;
}

.sdc-site-tile--breaking .sdc-site-tile__headline {
  font-weight: 400 !important;
}

// overrides
// @QUESTION - Where does this go?
@media all and (max-width: 599px) {
  .related-articles-wrap .sdc-site-tiles__item {
    counter-increment: items-to-show-3;
  }
}

.listing-wrap .sdc-site-tiles__item {
  counter-increment: items-to-show-6;
}

 // remove gap when section wrap is directly after localnav
 .sdc-site-localnav ~ .section-wrap[class] {
  margin-top: calc(var(--component-margin) * -1);
}

div.sdc-article-sibling-links[class] {
  z-index: 100;
}

.sdc-article-sibling-links ~ .sdc-site-footer[class] {
  margin-top: 0;
}

.related-articles-wrap {
  padding-bottom: 75px;
}

.sdc-site-fixres__additional-table {
  padding: 1em 0 1em;
  padding: calc(6px + 1vmin);
  text-align: center;
}

.sdc-site-fixres__additional-table-link {
  display: inline-block;
  padding: 2px;
  font-size: 18px;
  margin-bottom: 1em;
}

.sdc-site-fixres__additional-table-link:after {
  @include sdc-ui-link-arrow();
}

.sdc-article-body .sdc-article-widget.sdc-article-gallery {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sdc-article-gallery:not(.sdc-article-widget--full-bleed),
.sdc-site-video:not(.sdc-article-widget--full-bleed),
.sdc-article-image:not(.sdc-article-image--full-bleed), .sdc-site-roadblock:not(.sdc-article-widget--full-bleed) {
  max-width: 976px !important;
  margin-left: auto;
  margin-right: auto;
}

.sdc-site-opta-widget[data-is-olympics-widget="true"] {
  min-height: 450px !important;
}

.sdc-site-video--amp {

  .sdc-site-video__media-container {
    padding-bottom: 0;
  }
}

.sdc-video-embed {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.sdc-video-embed__iframe {
  border: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.ncpost-list-post[class],
.ncpost-linked[class], 
.ncpost-pinned-post[class] {
  grid-template-columns: minmax(104px, 15%) 1fr 64px;
}

// Compact trending component side articles
.sdc-site-trending--compact + .sdc-site-outbrain--SB_1 {
  margin-top: unset;
}

// Compact trending component side articles
.sdc-advert + .sdc-site-trending--compact {
  margin-top: var(--group-margin);
  margin-bottom: var(--group-margin);
  max-width: calc(var(--site-width-amp)/2);
  width: calc((var(--site-width-amp)/2) - 2px);
}

// Hiding Column 2 below 940px
@media (max-width: 939px){
  .sdc-site-layout__col2 .sdc-site-layout-sticky-region {
    display: none;
  }
}

// Overrides display none on adverts
.site-component-vertical-margin[data-ad-format='mpu-1'] .sdc-site-au__mpu-1 {
  display: inline-block !important;
}

// Sets correct background for match data pages
.sdc-site-match-header ~ .section-wrap:has(.sdc-site-table__container, .sdc-site-match-stats, .sdc-site-team-formations, .sdc-site-last-games, .sdc-site-tiles) {
  background: var(--body-bg) !important;
}

// Hides gap on mobile view for match pages
@media all and (max-width: 480px) {
  .sdc-site-match-header ~ .sdc-site-localnav[class][data-component-name='ui-sitewide-localnav'] {
    margin-bottom: 0px;
  }
  // Adds gap to data page titles
  .sdc-site-match-header ~ .ui-sitewide-component-header__wrapper--h2,
  .sdc-site-match-header ~ .ui-sitewide-component-header__wrapper--h1 {
    padding-top: 20px;
  }
}

.global-sports-app {

  @media (prefers-color-scheme: dark) {

    .sdc-site-tile--breaking {
      background-color: var(--component-bg) !important;
    }
  }
}