.sdc-site-carousel {
  @extend .site-wrap-padding !optional;
  @extend .site-wrap--mobile-edge !optional;
  @extend .site-wrap !optional;
}

.sdc-site-carousel--sports-rail {
  background-color: var(--carousel-background);
  display: flex;
  align-items: center;
  width: var(--site-width);
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__wrap {
  padding: 0;
  box-shadow: none;
  background: none;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__rail-wrap {
  margin-top: 0;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__rail {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: self-end;
  position: relative;
  max-width: calc(var(--site-width-inner) + 5px);
  scroll-snap-type: x proximity;
  scroll-behavior: smooth;
  width: var(--site-width-inner);
  height: 23em;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scroll-snap-destination: 0 100%;
  overflow-y: hidden;
  gap: 18px;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__title-wrap {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__view-all {
  color: var(--link-color);
  font-weight: 700;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__title {
  color: var(--text-color);
  font-size: var(--display-3);
  font-weight: 400;
  font-style: normal;
  line-height: var(--line-height-500);
  display: flex;
  width: auto;
  align-items: baseline;
  margin-bottom: 0;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__rail-item .sdc-site-tile--has-link:hover .sdc-site-tile__body {
  color: var(--text-color);
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__nav {
  color: var(--text-color);
  border-bottom: 2px solid transparent;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__selected {
  border-bottom: 2px solid #000000;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__nav-wrap {
  margin-left: 0;
}

.sdc-site-carousel--sports-rail button.sdc-site-carousel__direction-nav {
  width: 2.2rem;
  height: 2.5rem;
  background: var(--sports-branding-watch-nav-button);
  box-shadow: var(--drop-shadow);
  border-radius: var(--space-l) 0 0 var(--space-l);
  color: white;
  position: absolute;
  appearance: none;
  cursor: pointer;
  z-index: 10;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  top: 54%;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__direction-nav[disabled] {
  pointer-events: none;
}

.sdc-site-carousel--sports-rail .sdc-site-carousel__direction-nav--left {
  left: 0;
}

.arrow--sports-rail {
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
}

.right, .left {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.sdc-site-carousel--sports-rail .sdc-site-tiles__item {
  width: var(--tile-width);
  aspect-ratio: 16/19;
  margin-left: 1px;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.15), 1px 1px 0 0 rgba(119, 119, 119, 0.05);
  --tile-width: calc((var(--site-width-inner) - (var(--space-s) * 2)) / 3);
  --tile-width-mobile: calc((var(--site-width-inner) - (var(--space-xl) * 2)) / 3);
}

.sdc-site-carousel--sports-rail .sdc-site-tiles__item:hover {
  color: var(--text-color);
}

.sdc-site-carousel--sports-rail div.sdc-site-tile--has-link .sdc-site-tile__image {
  height: 100%;
  min-height: 1px;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  transition: transform var(--transition-duration-l);
  width: 100%;
  will-change: transform;
}

.sdc-site-carousel--sports-rail div.sdc-site-tile--has-link:hover .sdc-site-tile__image {
  transform: scale(1.1);
}

.sdc-site-carousel--sports-rail .sdc-site-tile__snippet,
.sdc-site-carousel--sports-rail .sdc-site-tile__headline-text {
  color: var(--black-op60);
  white-space: normal;
  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.sdc-site-carousel--sports-rail .sdc-site-tile--has-link .sdc-site-tile__body .sdc-site-tile__headline:after {
  background: var(--glass-treatment);
  transition: opacity var(--transition-duration-l);
}

.sdc-site-carousel--sports-rail a.sdc-site-tile__tag-link:hover {
  color: var(--text-color);
}

@media all and (min-width:900px) and (max-width:960px) {

  .sdc-site-carousel--sports-rail {
    margin-left: var(--space-m);
    padding-left: 0;
    width: 100%;
    padding-right: 0;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__rail {
    width: 100%;
    height: auto;
  }

  .sdc-site-carousel--sports-rail .glints-box:not(.noskin), {
    background-clip: unset;
    border: none;
  }

  .sdc-site-carousel--sports-rail .glints-box:not(.noskin):before {
    box-shadow: none;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__direction-nav {
    display: none;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) > .sdc-site-tile__figure {
    width: 100%;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) {
    width: var(--tile-width-mobile);
    margin-right: 2px;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__title-wrap {
    width: calc(100% - 5vw);;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__nav-wrap {
    margin-bottom: var(--component-gap);
  }
}

@media all and (max-width: 899px) {

  .sdc-site-carousel--sports-rail {
    width: 100%;
    padding-right: 0;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__rail {
    width: 100%;
    padding-right: 0;
    gap: 12px;
    height: auto;
    align-items: center;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__rail-wrap {
    margin-top: 0;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) {
    width: var(--tile-width-mobile);
    margin-right: 2px;
    height: -webkit-max-content;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__nav {
    margin-bottom: 0;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__direction-nav {
    display: none;
  }

  .sdc-site-carousel--sports-rail .glints-box:not(.noskin), {
    background-clip: unset;
    border: none;
  }

  .sdc-site-carousel--sports-rail .glints-box:not(.noskin):before {
    box-shadow: none;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__title-wrap {
    width: calc(100% - 3vw);
    margin-bottom: var(--component-gap);
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__nav-wrap {
    margin-bottom: var(--component-gap);
  }
}

@media all and (min-width:481px) and (max-width:599px) {

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) {
    aspect-ratio: 2 / 1;
    height: -webkit-max-content;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) > .sdc-site-tile__figure {
    display: table-caption;
    width: 100%;
    left: 0;
    top: 0;
  }

  .sdc-site-carousel--sports-rail .glints-box:not(.noskin):before {
    content: unset;
  }
}

@media all and (max-width:480px) {

  .sdc-site-carousel--sports-rail {
    margin-left: var(--space-m);
    width: auto;
  }

  .sdc-site-carousel--on-now .sdc-site-carousel__rail {
    gap: 16px;
  }

  .sdc-site-carousel--sports-rail .sdc-site-carousel__rail,
  .sdc-site-carousel--on-now .sdc-site-carousel__rail {
    height: auto;
    gap: 12px;
  }

  .sdc-site-carousel--sports-rail div.sdc-site-tile__playback {
    bottom: 35%;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) > .sdc-site-tile__figure {
    display: table-caption;
    width: 100%;
  }

  .sdc-site-carousel--sports-rail .sdc-site-tile:not([data-type*=hero]) {
    aspect-ratio: 2 / 1;
    height: -webkit-max-content;
  }
}