/* DE Christmas Header Styling
  =========================================== */

// Site Header

@import "../component/date-time.scss";

.sdc-site-header {
  &--weihnachten {
    position: relative;
    background-image: url("../../assets/weihnachten/skysport-web-header-bg-540x56.jpg?bypass-service-worker");
    background-repeat: repeat-x;
    background-size: auto 100%;
    background-position: center;
    @media (min-width: 540px) {
      background-image: url("../../assets/weihnachten/skysport-web-header-bg-920x56.jpg?bypass-service-worker");
    }
    @media (min-width: 920px) {
      font-weight: bold;
      background-image: url("../../assets/weihnachten/skysport-web-header-bg-1920x151.jpg?bypass-service-worker");
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #0b0b44 0, #000);
      opacity: .4;
      width: 100%;
      height: 56px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @media (min-width: 920px) {
        height: 151px;
      }
    }

    &__snow {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 56px;
      overflow: hidden;
      pointer-events: none;
      @media (min-width: 920px) {
        height: 151px;
      }

      @media (min-width: 920px) {
        // snow animation and creation
        $s1: "";
        $s2: "";
        @for $i from 1 through 400 {
          $s1: $s1 + random(1000)*3px + " " + random(800)*0.6px + " " + 0 + " " + random(50)*-0.01rem + #fff;
          $s2: $s2 + random(1000)*3px + " " + random(700)*0.5px + " " + 0 + " " + random(50)*-0.01rem + #fff;
          @if $i < 400 {
            $s1: $s1 + ",";
            $s2: $s2 + ",";
          }
        }
        @keyframes snow {
          100% {
            transform: translateY(800px);
          }
        }

        // snow elements
        div {
          border-radius: 50%;
          opacity: 0.8;
          position: absolute;
          top: -490px;
          animation-name: snow;
          animation-timing-function: linear;
          animation-iteration-count: infinite;

          @media (prefers-reduced-motion: reduce) {
            animation: none;
          }

          &:nth-child(1),
          &:nth-child(2) {
            width: 0.8rem;
            height: 0.8rem;
            filter: blur(6px);
            box-shadow: #{$s2};
            animation-delay: 1s;
            animation-duration: 13s;
          }

          &:nth-child(2) {
            animation-delay: 5s;
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 1rem;
            height: 1rem;
            filter: blur(4px);
            box-shadow: #{$s1};
            animation-delay: 8s;
            animation-duration: 11s;
          }

          &:nth-child(4) {
            animation-delay: 12s;
          }
        }
      }

    }
  }
  &__inner {
    z-index: 3;
  }
}
